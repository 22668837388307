import { qrcode } from './qrcode-lib/qrcode';

function escapeQrWifiSpecialChars(str) {
    return str.split("\\").join("\\\\")
        .split(";").join("\\;")
        .split(",").join("\\,")
        .split(":").join("\\:");
}

/*
 * "data" example:
 *  {
 *      network: "NetworkName",
 *      password: "some_password", // if password is used
 *      protection: "WPA" // WPA|WEP|nopass
 *  }
 * 
 * returns link for QR code as string
 */
function prepareWifiLink(data) {
    let result = 'WIFI:';

    if (data.protection !== 'nopass') {
        result += 'T:' + data.protection + ';';
        result += 'P:' + escapeQrWifiSpecialChars(data.password) + ';';
    }
    
    result += 'S:' + escapeQrWifiSpecialChars(data.network) + ';';
    result += ';';
    return result
}

/*
 * returns QR code svg as string
 */
function drawQrCode(wifiLink) {
    const typeNumber = 0; // todo comment it
    const errorCorrectionLevel = 'H'; // todo comment it

    const qr = qrcode(typeNumber, errorCorrectionLevel);
    qr.addData(wifiLink, 'Byte');
    qr.make();
    return qr.createSvgTag({
        margin: 0,
        fillColor: '#333333'
    });
}

/*
 * "data" example:
 *  {
 *      network: "NetworkName",
 *      password: "some_password", // if password is used
 *      protection: "WPA" // WPA|WEP|nopass
 *  }
 * 
 * returns qr code svg as string
 */
export function buildQrCode(data) {
    const link = prepareWifiLink(data);
    const qrCodeSvg = drawQrCode(link);
    return qrCodeSvg;
}
