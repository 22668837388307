import React from 'react';
import {buildQrCode} from "./qrcode/wifiQrUtils";

type CardProps = {
    paddingTop: number
}

function Card(props: CardProps) {
    const jsonStr = decodeURI(window.location.hash.substr(1))
    const data = JSON.parse(jsonStr)

    const containerS = {
        height: `calc(100vh - ${props.paddingTop}px)`,
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center' as const,
        alignItems: 'center' as const
    }

    const cardS = {
        width: 'calc(100vw - 32px - 32px - 16px)',
        maxHeight: `calc(100vh - ${props.paddingTop * 2}px)`,
        maxWidth: `calc(100vh - ${props.paddingTop * 3}px)`,
        borderRadius: '10px',
        marginLeft: '16px',
        marginRight: '16px',
        backgroundColor: '#ffffff',
        paddingTop: '32px',
        paddingBottom: '32px',
        paddingLeft: '24px',
        paddingRight: '24px',
    }

    const qrCodeSvgStr = buildQrCode({
        network: data.net,
        password: data.pass,
        protection: data.sec
    })

    const credS = (isFirst: any) => {
        return {
            fontFamily: 'TitilliumWeb-Regular',
            fontSize: '16px',
            textAlign: 'center' as const,
            paddingTop: isFirst ? '32px' : '16px'
        }
    }

    const credTitleS = {
        display: 'inline',
        fontFamily: 'TitilliumWeb-Bold'
    }

    return (
        <div style={containerS}>
            <div style={cardS}>
                <div className="qr"
                     dangerouslySetInnerHTML={{ __html: qrCodeSvgStr }}/>
                <div style={credS(true)}><div style={credTitleS}>Network </div>{ data.net }</div>
                { data.pass && <div style={credS(false)}><div style={credTitleS}>Password </div>{ data.pass }</div> }
            </div>
        </div>
    )
}

export default Card