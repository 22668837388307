import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css'

declare global {
    interface Window { RenderApp: any; }
}

window.RenderApp = (config: any) => {
    ReactDOM.render(
        <React.StrictMode>
            <App config={config}/>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
