import React from 'react';
import logoIcn from './logo-icn.svg'
import Card from "./Card";
import {Analytics, trackEvent} from "./analytics/Analytics";
import AppConfig from "./app-config/AppConfig";

type AppProps = {
    config: any
}

function App(props: AppProps) {
    const mainContainerS = {
        backgroundImage: 'radial-gradient(circle at 50% 0, #343c50, #1d2539 82%)',
        width: '100hh',
        height: '100vh'
    }

    const logoContainerS = {
        fontFamily: 'FredokaOne-Regular',
        fontSize: '14px',
        color: '#ffffff',
        paddingTop: '24px',
        textAlign: 'center' as const
    }

    const logoIcnS = {
        backgroundImage: `url(${logoIcn})`,
        width: '24px',
        height: '17px',
        display: 'inline-block',
        backgroundSize: '24px 17px',
        verticalAlign: 'sub',
        marginRight: '6px'
    }

    const tipS = {
        fontFamily: 'TitilliumWeb-Regular',
        fontSize: '16px',
        color: 'rgba(255, 255, 255, 0.7)',
        textAlign: 'center' as const,
        marginTop: '24px',
        height: '26px',
        paddingBottom: '10px'
    }

    const onLogoClick = () => {
        window.open('https://waflee.app', '_blank');
    }

    const isStandalone = window.matchMedia('(display-mode: standalone)').matches
    if (isStandalone) {
        trackEvent('opened-as-standalone')
    }

    return (
        <AppConfig.Provider value={props.config}>
            <div style={mainContainerS}>
                <Analytics/>
                <div style={logoContainerS} onClick={onLogoClick}><div style={logoIcnS}/>waflee.app</div>
                { !isStandalone && <div style={tipS}>Tip: Add this page to your home screen</div> }
                <Card paddingTop={ isStandalone ? 90 : 102 }/>
            </div>
        </AppConfig.Provider>
    );
}

export default App;
